import {type AxiosResponse} from "axios";
import client from "../../client";
import {type Components, type Paths} from "../../../types/client";

export enum EAuthUrl {
  LOGIN = "/auth/login",
  REFRESH = "auth/refresh",
  CHANGE_PASSWORD = "auth/change-password",
}

export const authLogin = async (
  body: Components.Schemas.LoginRequest,
): Promise<AxiosResponse<Components.Schemas.AuthSuccessResponse>> => {
  return await client.post(EAuthUrl.LOGIN, body);
};

export const authRefresh = async (
  params: Paths.RefreshUserAuthentication.QueryParameters,
): Promise<AxiosResponse<Components.Schemas.AuthSuccessResponse>> => {
  return await client.get(EAuthUrl.REFRESH, {
    params,
  });
};

export const authChangePassword = async (
  body: Components.Schemas.ChangePasswordRequest,
): Promise<AxiosResponse<Components.Schemas.FailResponse>> => {
  return await client.post(EAuthUrl.CHANGE_PASSWORD, body);
};
