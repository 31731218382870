import React from "react";
import {AuthContextProvider} from "../../provider/AuthProvider";
import Routes from "./routes";
import "bootstrap/dist/css/bootstrap.css";
import "assets/less/styles.less";

export class App extends React.Component {
  render(): React.JSX.Element {
    return (
      <AuthContextProvider>
        <Routes />
      </AuthContextProvider>
    );
  }
}
