import {type ReactNode, createContext, useState} from "react";
import jwt_decode from "jwt-decode";
import {authLogin, authRefresh} from "../api/services/Auth/Auth";

interface AuthProvider {
  children: ReactNode;
}

const AuthContext = createContext<any | null>(null);
export const AuthContextProvider = ({children}: AuthProvider) => {
  const [user, setUser] = useState(() => {
    try {
      const token = localStorage.getItem("token");

      if (token !== null) {
        return jwt_decode(token);
      }

      return null;
    } catch (error) {
      return null;
    }
  });

  const login = async (body: any) => {
    const response = await authLogin(body);

    if (response.data.token && response.data.refreshToken) {
      window.localStorage.setItem("token", response.data.token);
      window.localStorage.setItem("refreshToken", response.data.refreshToken);

      setUser(jwt_decode(response.data.token));
    }
  };

  const refresh = async (params: any) => {
    const response = await authRefresh(params);

    try {
      if (response.data.token && response.data.refreshToken) {
        window.localStorage.setItem("token", response.data.token);
        window.localStorage.setItem("refreshToken", response.data.refreshToken);

        setUser(jwt_decode(response.data.token));
      }
    } catch (e) {
      localStorage.removeItem("tokens");

      setUser(null);
    }
  };

  const logout = async () => {
    localStorage.clear();

    setUser(null);
  };

  return (
    <AuthContext.Provider value={{user, login, refresh, logout}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
