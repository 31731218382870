import axios from "axios";
// import * as process from "process";
import {EAuthUrl} from "./services/Auth/Auth";

/*
import openapi from "./definition/openapi.json";
import OpenAPIClientAxios from "openapi-client-axios";

import {type Client} from "../types/client";

const definition: string = JSON.parse(JSON.stringify(openapi));

const api = new OpenAPIClientAxios({
  definition,
});

const openAPIClient = await api.getClient<Client>();
 */

const BASE_URL = "/api";

let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

/*
if (process.env.NODE_ENV === "development") {
  openAPIClient.defaults.baseURL = process.env.REACT_APP_API_URL;
}

openAPIClient.interceptors.request.use(config => {
  const token = localStorage.getItem("token");

  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

openAPIClient.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      !originalRequest._retry &&
      originalRequest.url !== "/auth/login" &&
      error.response.status === 401
    ) {
      if (isRefreshing) {
        return await new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(async token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return await client(originalRequest);
          })
          .catch(async err => {
            return await Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem("refreshToken");

      return await new Promise(function (resolve, reject) {
        openAPIClient
          .RefreshUserAuthentication(null, {token: refreshToken})
          .then(({data: {token, refreshToken}}) => {
            if (token && refreshToken) {
              window.localStorage.setItem("token", token);
              window.localStorage.setItem("refreshToken", refreshToken);
              openAPIClient.defaults.headers.common.Authorization = `Bearer ${token}`;
              originalRequest.headers.Authorization = `Bearer ${token}`;
              processQueue(null, token);
              resolve(openAPIClient(originalRequest));
            }
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return await Promise.reject(error);
  },
);

const res = openAPIClient.GetThemes();

console.log(res);
 */

const client = axios.create({
  baseURL: BASE_URL,
});

client.interceptors.request.use(config => {
  const token = localStorage.getItem("token");

  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    if (
      originalRequest.url !== EAuthUrl.LOGIN &&
      !originalRequest._retry &&
      error.response.status === 401
    ) {
      if (isRefreshing) {
        return await new Promise(function (resolve, reject) {
          failedQueue.push({resolve, reject});
        })
          .then(async token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return await client(originalRequest);
          })
          .catch(async err => {
            return await Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      const refreshToken = window.localStorage.getItem("refreshToken");
      return await new Promise(function (resolve, reject) {
        client
          .get(EAuthUrl.REFRESH, {params: {token: refreshToken}})
          .then(({data}) => {
            window.localStorage.setItem("token", data.token);
            window.localStorage.setItem("refreshToken", data.refreshToken);
            client.defaults.headers.common.Authorization = `Bearer ${data.token}`;
            originalRequest.headers.Authorization = `Bearer ${data.token}`;
            processQueue(null, data.token);
            resolve(client(originalRequest));
          })
          .catch(err => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return await Promise.reject(error);
  },
);

export default client;
