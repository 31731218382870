import {useContext} from "react";
import {Navigate, Outlet} from "react-router-dom";
import AuthContext from "../../../provider/AuthProvider";
import {ERoute} from "../configs/ERoute";

export const ProtectedRoute = () => {
  const {user} = useContext(AuthContext);

  if (user === null) {
    return <Navigate to={ERoute.AUTH} />;
  }

  return <Outlet />;
};
