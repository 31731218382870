import React, {useContext} from "react";
import {Navigate, RouterProvider, createBrowserRouter} from "react-router-dom";
import {ERoute} from "../configs/ERoute";
import AuthContext from "../../../provider/AuthProvider";
import {ProtectedRoute} from "./ProtectedRoute";
import {AuthPage} from "../../../pages/Auth";
import {PresentationsPage} from "../../../pages/Presentations";
import {SlidesPage} from "../../../pages/Slides";
import {SlidePage} from "../../../pages/Slide";
import {MyPresentationsPage} from "../../../pages/MyPresentations";
import {RatingPage} from "../../../pages/Rating";
import {ProfilePage} from "../../../pages/Profile";

const Routes = () => {
  const {user} = useContext(AuthContext);

  const routesForPublic = [
    {
      path: "/about",
      element: <div>About Us</div>,
    },
  ];

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "",
          element: <Navigate to={ERoute.PRESENTATIONS} />,
        },
        {
          path: ERoute.PRESENTATIONS,
          element: (
            <React.Suspense>
              <PresentationsPage />
            </React.Suspense>
          ),
        },
        {
          path: ERoute.SLIDES,
          element: (
            <React.Suspense>
              <SlidesPage />
            </React.Suspense>
          ),
        },
        {
          path: ERoute.SLIDE,
          element: (
            <React.Suspense>
              <SlidePage />
            </React.Suspense>
          ),
        },
        {
          path: ERoute.MY_PRESENTATIONS,
          element: (
            <React.Suspense>
              <MyPresentationsPage />
            </React.Suspense>
          ),
        },
        {
          path: ERoute.RATING,
          element: (
            <React.Suspense>
              <RatingPage />
            </React.Suspense>
          ),
        },
        {
          path: ERoute.PROFILE,
          element: (
            <React.Suspense>
              <ProfilePage />
            </React.Suspense>
          ),
        },
        {
          path: "*",
          element: <Navigate to={ERoute.PRESENTATIONS} />,
        },
      ],
    },
  ];

  const routesForNotAuthenticatedOnly = [
    {
      path: ERoute.AUTH,
      element: (
        <React.Suspense>
          <AuthPage />
        </React.Suspense>
      ),
    },
  ];

  const router = createBrowserRouter([
    ...routesForPublic,
    ...(user === null ? routesForNotAuthenticatedOnly : []),
    ...routesForAuthenticatedOnly,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
